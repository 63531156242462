import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { RouterOutlet } from '@angular/router';
import { NgClass } from '@angular/common';
import { ResponsiveHelperComponent } from './shared/components/responsive-helper/responsive-helper.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { PwaService } from './shared/services/pwa.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: 'LOCALE_ID',
      useValue: 'pl-PL', // Polish locale for currency
    },
  ],
  standalone: true,
  imports: [NgClass, RouterOutlet, ResponsiveHelperComponent, LayoutComponent],
})
export class AppComponent {
  title = 'Ogarni.AI';

  constructor(public themeService: ThemeService, private pwaService: PwaService) {}
}
